<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">사용자관리</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div>
          <div class="">
            <div class="form-inp sm">
              <v-select
                  :items="dropCustKeys"
                  item-text="CD_NM"
                  item-value="CD"
                  outlined
                  hide-details
                  label="회사구분"
                  placeholder="선택하세요"
                  v-model="ASP_NEWCUST_KEY"
                  v-on:change="getList"
                :readonly="RDOnly"
              >
                <template v-slot:label>
                  회사구분
                  <v-icon color="#EF9191">mdi-circle-small</v-icon>
                </template>
              </v-select>
            </div>
            <v-text-field
                class="form-inp ml-2"
                label="사용자ID"
                outlined
                hide-details
                v-model="USER_ID"
                v-on:keyup.enter="searchUserInfo"
            >
            </v-text-field>
            <v-text-field
                class="form-inp ml-2"
                label="사용자명"
                outlined
                hide-details
                v-model="USER_NM"
                v-on:keyup.enter="searchUserInfo"
            >
            </v-text-field>
            <v-text-field
                class="form-inp ml-2"
                label="닉네임"
                outlined
                hide-details
                v-model="USER_NICK"
                v-on:keyup.enter="searchUserInfo"
            >
            </v-text-field>
            <div class="form-inp sm ml-2">
              <v-select
                  :items="dropYn"
                  item-text="title"
                  item-value="value"
                  outlined
                  hide-details
                  label="사용여부"
                  placeholder="선택하세요"
                  v-model="USE_YN"
              >
              </v-select>
            </div>
          </div>
          <div class="mt-2">
            <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                content-class="calendar-modal "
                color="#FFF"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateRangeText"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    outlined
                    hide-details
                    class="form-inp icon-calendar"
                    append-icon="svg-calendar"
                    label="조회기간"
                ></v-text-field>
              </template>
              <div class="datepicker-range">
                <v-date-picker
                    v-model="dates[0]"
                    no-title
                    dark
                    color="#F7AFAE"
                    locale="ko-KR"
                    :first-day-of-week="1"
                    :day-format="mixin_getDate"
                    @change="startDate"
                ></v-date-picker>
                <v-date-picker
                    v-model="dates[1]"
                    dark
                    color="#F7AFAE"
                    no-title
                    :min="dates[0]"
                    locale="ko-KR"
                    :first-day-of-week="1"
                    :day-format="mixin_getDate"
                    @change="endDate"
                ></v-date-picker>
              </div>
              <div class="text-center pt-3 pb-3">
                <v-btn outlined class="btn-default" @click="menu1 = false"
                >확인</v-btn
                >
              </div>
            </v-menu>
            <v-text-field
                class="form-inp ml-2"
                label="최근접속IP"
                outlined
                hide-details
                v-model="CNNCT_IP"
                v-on:keyup.enter="searchUserInfo"
            >
            </v-text-field>
            <div class="form-inp sm ml-2">
              <v-select
                  :items="dropGroupItems"
                  item-text="NM"
                  item-value="CD"
                  outlined
                  hide-details
                  label="권한그룹명"
                  placeholder="선택하세요"
                  v-model="ATRT_GROUP_ID"
              ></v-select>
            </div>
            <v-radio-group
                v-model="radioGroupSelected"
                row
                class="radio-inline ml-2"
            >
              <template v-slot:label>
                <span class="in-label">정렬조건</span>
              </template>
              <v-radio
                  v-for="(rad01, index) in radioGroup"
                  :key="radioGroup[index].text"
                  :label="rad01.text"
                  :on-icon="'svg-rad-on'"
                  :off-icon="'svg-rad-off'"
                  :value="rad01.value"
              >
              </v-radio>
            </v-radio-group>
            <div class="form-inp sm ml-2">
              <v-select
                  :items="dropA"
                  item-text="CD_NM"
                  item-value="CD"
                  outlined
                  hide-details
                  label="소속A"
                  placeholder="선택하세요"
                  v-model="USER_ATTR_A"
              ></v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                  :items="dropB"
                  item-text="CD_NM"
                  item-value="CD"
                  outlined
                  hide-details
                  label="소속B"
                  placeholder="선택하세요"
                  v-model="USER_ATTR_B"
              ></v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                  :items="dropC"
                  item-text="CD_NM"
                  item-value="CD"
                  outlined
                  hide-details
                  label="소속C"
                  placeholder="선택하세요"
                  v-model="USER_ATTR_C"
              ></v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                  :items="dropD"
                  item-text="CD_NM"
                  item-value="CD"
                  outlined
                  hide-details
                  label="소속D"
                  placeholder="선택하세요"
                  v-model="USER_ATTR_D"
              ></v-select>
            </div>
          </div>
        </div>

        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" @click="searchUserInfo" id="btnSearch">조회</v-btn>
        </div>
      </div>
    </div>
    <!-- 사용자현황 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        사용자현황
        <div class="ml-auto">
          <v-btn
              v-if="this.mixin_set_btn(this.$options.name, 'btnNew')"
              outlined
              class="btn-default"
              @click="userAdd"
              id="btnNew"
          >신규</v-btn
          >
          <v-btn
              v-if="this.mixin_set_btn(this.$options.name, 'btnDetail')"
              outlined
              class="btn-default ml-2"
              @click="sendDetailInfo"
              id="btnDetail"
          >상세</v-btn
          >
          <v-btn
              v-if="this.mixin_set_btn(this.$options.name, 'btnRegiGroup')"
              outlined
              class="btn-default ml-2"
              @click="groupAdd"
              id="btnNewAttr"
          >소속등록</v-btn
          >
        </div>
        <v-dialog v-if="dialogM810401P01"   max-width="655" content-class="square-modal min-auto"  persistent v-model="dialogM810401P01">
          <dialog-M810401P01
              headerTitle="사용자정보 신규등록"
              :param="M810401P01Param"
              p01
              @hide="hideDialog('M810401P01')"
              @submit="submitDialog('M810401P01')"
          >
          </dialog-M810401P01>
        </v-dialog>
        <v-dialog v-if="dialogM810401P02"  max-width="655" content-class="square-modal min-auto" persistent v-model="dialogM810401P02">
          <dialog-M810401P01
              p02
              :param="M810401P02Param"
              headerTitle="사용자 상세정보"
              @hide="hideDialog('M810401P02')"
              @submit="submitDialog('M810401P02')"
              @reset="resetDialog('M810401P02')"
              @unlock="unlockDialog('M810401P02')"
          >
          </dialog-M810401P01>
        </v-dialog>
        <v-dialog  max-width="655" content-class="square-modal min-auto" v-if="dialogM810401P03" persistent v-model="dialogM810401P03">
          <dialog-M810401P01
              p03
              :param="M810401P03Param"
              headerTitle="사용자정보 소속등록"
              @hide="hideDialog('M810401P03')"
              @submit="submitDialog('M810401P03')"
          >
          </dialog-M810401P01>
        </v-dialog>
      </h2>
      <div class="box-ct">
        <v-data-table
            dense
            height="500px"
            :headers="gridDataHeaders"
            :items="gridDataText"
            :items-per-page="30"
            item-key="index"
            :page.sync="page"
            hide-default-footer
            single-select
            class="grid-default"
            fixed-header
            @page-count="pageCount = $event"
            v-model="selGroupCd"
            v-on:click:row="getListSel"
            v-on:dblclick:row="sendDetailInfo"
        >
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="totalVisible"
              next-icon="svg-paging-next"
              prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
      </div>
    </div>
    <!--// 사용자현황 -->
  </div>
</template>

<script>
import DialogM810401P01 from "./M810401P01.vue";
import api from "@/store/apiUtil";
import {mixin} from "@/mixin/mixin";

export default {
  name: "MENU_M810401", //name은 'MENU_' + 파일명 조합
  components: {
    DialogM810401P01,
  },
  mixins:[mixin],
  data() {
    return {
      selGroupCd : [],
      M810401P01Param : {},
      M810401P02Param : {},
      M810401P03Param : {},
      ATTR_DIV_CD: '',
      dialogM810401P01: false,
      dialogM810401P02: false,
      dialogM810401P03: false,
      menu1: false,
      menu2: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      dates: [
        '',
        '',
      ],
      radioGroup: [
        {
          text: "사용자명",
          value : "USER_NM",
        },
        {
          text: "최근접속일시",
          value : "JOIN_DATE",
        },
      ],
      radioGroupSelected: "USER_NM",
      dropCustKeys: [],
      dropYn:[
        { title: "전체", value:"" },
        { title: "사용", value:"Y" },
        { title: "미사용", value:"N" },
      ],
      dropGroupItems:[
        {NM: "전체", CD:""},
      ],
      dropA:[{CD_NM : "전체", CD : ""}],
      dropB:[{CD_NM : "전체", CD : ""}],
      dropC:[{CD_NM : "전체", CD : ""}],
      dropD:[{CD_NM : "전체", CD : ""}],
      gridDataHeaders: [
        {
          text: "No",
          align: "center",
          value: "index",
          width: "50px",
          sortable: true,
        },
        { text: "회사명", value: "ASP_NEWCUST_KEY_NM", align: "center", sortable: true },
        {
          text: "사용자ID",
          value: "USER_ID",
          align: "left",
          sortable: true,
        },
        {
          text: "사용자명",
          value: "USER_NM",
          align: "left",
          sortable: true,
        },
        { text: "닉네임", value: "USER_NICK", align: "left", sortable: true },
        {
          text: "권한그룹명",
          value: "ATRT_GROUP_NM",
          align: "left",
          sortable: true,
        },
        {
          text: "전문상담명",
          value: "INQRY_TYP_NM",
          align: "left",
          sortable: true,
        },
        {
          text: "비밀번호틀린횟수",
          value: "PWD_FAIL_FREQ",
          align: "center",
          sortable: true,
        },
        {
          text: "개인챗팅허용수",
          value: "MAX_CHAT_AGENT",
          align: "center",
          sortable: true,
        },
        {
          text: "최근접속IP",
          value: "CNNCT_IP",
          align: "center",
          sortable: true,
        },
        {
          text: "최근접속일시",
          value: "LAST_LOGIN_DT",
          align: "center",
          sortable: true,
        },
        {
          text: "등록일시",
          value: "REG_DTTM",
          align: "center",
          sortable: true,
        },
        { text: "소속A", value: "ATTR_DIV_NM_A", align: "center", sortable: true },
        { text: "소속B", value: "ATTR_DIV_NM_B", align: "center", sortable: true },
        { text: "소속C", value: "ATTR_DIV_NM_C", align: "center", sortable: true },
        { text: "소속D", value: "ATTR_DIV_NM_D", align: "center", sortable: true },
      ],
      gridDataText: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      totalVisible: 10,
      textareaRules: [(v) => v.length <= 200 || "Max 200 characters"],
      textareaValue: "200자 제한!",

      //v-model
      USER_ID_KEY :'',
      ASP_NEWCUST_KEY:'',
      USE_YN :'',
      USER_ID : '',
      USER_NM : '',
      USER_NICK : '',
      CNNCT_IP:'',
      ATRT_GROUP_ID:'',
      USER_ATTR_A :'',
      USER_ATTR_B :'',
      USER_ATTR_C :'',
      USER_ATTR_D :'',
      SORT_ORDR : '',

      ADMIN_ID : "20190125141939798TWB52868",

      RDOnly:false,
    };

  },
  mounted() {
    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.ASP_NEWCUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
    
    this.init();
    this.getItems();
    this.getList();

  },
  methods: {
    async init(){
      this.dropGroupItems.push(...await this.mixin_user_auth_get());
      this.USER_ID_KEY = this.$store.getters['userStore/GE_USER_COMPANY'].userId;
    },
    async getItems() {
      if (this.$store.getters['userStore/GE_USER_ROLE'].atrtGroupId === this.ADMIN_ID) {
        let tempItems  = await this.mixin_getAllAspData();
        this.dropCustKeys = tempItems;
      } else {
        this.dropCustKeys = this.$store.getters['userStore/GE_USER_COMPANY'];
      }
    },

    //Table row 클릭이벤트
    getListSel(item, row) {
      if(item != null){
        this.activeRow(row,true);
      }
    },

    activeRow(row, value){
      console.log(row);
      this.selectedRow = row;
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
    },

    async getList(){
      let aspNewcustKey = this.ASP_NEWCUST_KEY;
      //회사구분 변경시 소속 콤보박스에 selected 된것 초기화

      this.dropA = await this.mixin_attr_get(aspNewcustKey, "A","전체");
      this.dropB = await this.mixin_attr_get(aspNewcustKey, "B","전체");
      this.dropC = await this.mixin_attr_get(aspNewcustKey, "C","전체");
      this.dropD = await this.mixin_attr_get(aspNewcustKey, "D","전체");

      this.USER_ATTR_A = "";
      this.USER_ATTR_B = "";
      this.USER_ATTR_C = "";
      this.USER_ATTR_D = "";

    },

    showAlert(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '회사구분란에서 회사를 선택후 이용해주십시오',
        iconClass: 'svg-error-lg',
        type: 'default',
      })
    },
    async searchUserInfo(){
      let companyKey = this.ASP_NEWCUST_KEY;
      this.selGroupCd = [];
      this.gridDataText = [];

      if(companyKey){
        let requestData = {
          headers: {
            SERVICE: this.initHeaders.SERVICE,
            METHOD: this.initHeaders.METHOD,
            TYPE: this.initHeaders.TYPE,
          },
          sendData:{}
        };

        // header 세팅
        requestData.headers["URL"] = "/api/setting/agent/manage/list";
        requestData.headers["METHOD"] = "list";
        requestData.headers["ROW_CNT"] = 50;
        requestData.headers["PAGES_CNT"] = 1;

        // sendData 세팅
        requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
        requestData.sendData["USER_ID"] = this.USER_ID;
        requestData.sendData["USER_NM"] = this.USER_NM;
        requestData.sendData["USER_NICK"] = this.USER_NICK;
        requestData.sendData["USE_YN"] = this.USE_YN;
        requestData.sendData["LAST_LOGIN_DT_FROM"] = this.dates[0];
        requestData.sendData["LAST_LOGIN_DT_TO"] = this.dates[1];
        requestData.sendData["CNNCT_IP"] = this.CNNCT_IP;
        requestData.sendData["ATRT_GROUP_ID"] = this.ATRT_GROUP_ID;
        requestData.sendData["USER_ATTR_A"] = this.USER_ATTR_A;
        requestData.sendData["USER_ATTR_B"] = this.USER_ATTR_B;
        requestData.sendData["USER_ATTR_C"] = this.USER_ATTR_C;
        requestData.sendData["USER_ATTR_D"] = this.USER_ATTR_D;
        requestData.sendData["SORT_ORDR"] = this.radioGroupSelected;
        requestData.sendData["rdoOrderNm"] = this.rdoOrderNm;
        requestData.sendData["rdoOrderDt"] = this.rdoOrderDt;
        requestData.sendData["USER_ID_KEY"] = this.USER_ID_KEY;
        requestData.sendData["A_USER_ID"] = this.A_USER_ID;

        this.result(requestData);
      }else{
        this.showAlert();
      }
    },
    getDetailListCallBack(response){
      let header = response.HEADER;
      let data = response.DATA;
      if(!this.mixin_isEmpty(response) && !header.ERROR_FLAG){
        if(!this.mixin_isEmpty(data) && data.length > 0) {
          for (let d of data) {
            for (let k of Object.keys(d)) {
              d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k];
            }
            d['index'] = this.gridDataText.length + 1;
            this.gridDataText.push(d);
          }
        }
      }else {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
      }

    },

    userAdd(){

      this.M810401P01Param = {
        headerTitle: "사용자정보 신규등록",
        data: '',
        type: "I",
      }

      this.showDialog('M810401P01')
    },

    sendDetailInfo(){
      if( Object.keys(this.selGroupCd).length === 0 ) {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: "선택된 항목이 존재하지 않습니다.",
          iconClass: "svg-error-lg",
          type: "default",
        });
        return;
      }
      this.M810401P02Param = {
        headerTitle: "사용자 상세정보",
        data: this.selGroupCd[0],
        type: "U",
      }
      console.log(this.selGroupCd);
      this.showDialog('M810401P02');

    },

    groupAdd(){
      if( Object.keys(this.selGroupCd).length === 0 ) {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: "선택된 항목이 존재하지 않습니다.",
          iconClass: "svg-error-lg",
          type: "default",
        });
        return;
      }
      console.log(this.selGroupCd[0]);
      this.M810401P03Param = {
        headerTitle: "사용자정보 소속등록",
        data: this.selGroupCd[0],
        type: "GU",
        dropA: this.dropA,
        dropB: this.dropB,
        dropC: this.dropC,
        dropD: this.dropD,
        ASP_NEWCUST_KEY : this.ASP_NEWCUST_KEY

      }
      this.showDialog('M810401P03')

    },



    async result(requestData){
      //api 호출 부분
      await api.post(requestData.headers.URL,   //api url입력
          //await axios.post('https://local.hkpalette.com:8443/'+this.URLData,{
          requestData.sendData
          , //api에서 사용될 data 입력
          {head: requestData.headers
          }) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
          .then((response) => {
            // string으로 넘어올 경우 에러임.
            if(typeof(response.data) === "string"){
              let responseData = JSON.parse(response.data.replaceAll("\n", ""));
              this.$store.commit("alertStore/openAlertDialog", {
                alertDialogToggle: true,
                msg: responseData.HEADER.ERROR_MSG,
                iconClass: "svg-error-lg",
                type: "default",
              });
              return;
            }

            if(response.data.HEADER.METHOD == "list" && requestData.headers.SERVICE == "setting.agent.manage"){
              this.getDetailListCallBack(response.data);
            }

          }) //api 호출 성공 이후 수행
          .catch((err) => {
            alert(err);
          }) //api호출 에러 작업 수행

      //결과값 보여주기
    },

    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    showDialog(type) {
      this[`dialog${type}`] = true;

    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    submitDialog(type) {
      console.log("완료");
      this.gridDataText = [];
      this.selGroupCd = [];
      this.searchUserInfo();
      this[`dialog${type}`] = false;
    },
    resetDialog(type) {
      console.log("reset");
      this.gridDataText = [];
      this.selGroupCd = [];
      this.searchUserInfo();
      this[`dialog${type}`] = false;
    },
    unlockDialog(type) {
      console.log("unlock");
      this.gridDataText = [];
      this.selGroupCd = [];
      this.searchUserInfo();
      this[`dialog${type}`] = false;
    },
  },

  computed: {
    dateRangeText() {
      let newStartDate = this.dates[0];
      let newEndDate = this.dates[1];
      let returnVal = "";
      if(newStartDate !== '' && newEndDate !== ''){
        returnVal = newStartDate + " ~ " + newEndDate
      }
      return returnVal;
    },
    initHeaders(){
      return {
        SERVICE : 'setting.agent.manage',
        METHOD : "",
        TYPE : 'BIZ_SERVICE',
      };
    },
  },
};
</script>

<style>

thead.v-data-table-header tr th {

  text-align-last: center;

}

</style>
